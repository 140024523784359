<template>
  <v-card class="pa-2 my-3">
    <v-card-title>Whatsapp</v-card-title>
    <div v-for="{ provider, providerConfiguration } in this.whatsappProviders" :key="`wapp-provider-${provider}`" class="px-4">
      <h4>Fornecedor: {{ provider }}</h4>
      <v-row>
        <v-col cols="2">
          <label class="subheading">Login:</label>
          <v-text-field v-model="providerConfiguration.login" :disabled="!editable" hide-details solo-inverted />
        </v-col>
        <v-col cols="2">
          <label class="subheading">Password:</label>
          <v-text-field v-model="providerConfiguration.password" :disabled="!editable" hide-details solo-inverted />
        </v-col>
        <v-col cols="7">
          <label class="subheading">Token:</label>
          <v-text-field v-model="providerConfiguration.token" :disabled="!editable" hide-details solo-inverted />
        </v-col>
        <v-col cols="1">
          <label class="subheading">Ativo:</label>
          <v-checkbox v-model="providerConfiguration.active" label="Ativo" :disabled="!editable" hide-details solo-inverted />
        </v-col>
      </v-row>
    </div>
    <v-row no-gutters>
      <btn-card-actions class="px-2 py-0" :editable.sync="editable" :saving="saving" @save="saveEdit" @cancel="cancelRestoreAccount" />
      <whatsapp-credentials-add-provider v-if="!editable" :current-providers="currentProviders" @add-provider="addProvider" />
    </v-row>
  </v-card>
</template>

<script>
import { MUTATION_UPDATE_WHATSAPP_CREDENTIALS } from '@/modules/accounts/graphql'

export default {
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    WhatsappCredentialsAddProvider: () => import('@/modules/accounts/components/edit/WhatsappCredentialsAddProvider.vue')
  },
  props: {
    account: {
      type: Object
    }
  },
  data: () => ({
    accountId: '',
    whatsappProviders: [],
    editable: false,
    saving: false
  }),
  computed: {
    currentProviders() {
      return this.whatsappProviders.map(provider => provider.provider)
    }
  },
  watch: {
    account(val) {
      if (val) {
        this.getProviderFromAccount(val)
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
    if (this.account) {
      this.getProviderFromAccount(this.account)
    }
  },
  methods: {
    getProviderFromAccount(adminAccount) {
      const providers = adminAccount?.whatsapp_credentials?.providers ?? {}
      this.whatsappProviders = Object.entries(providers).reduce((acc, [provider, { __typename, ...providerConfiguration }]) => {
        if (provider !== '__typename') {
          acc.push({ provider, providerConfiguration })
        }
        return acc
      }, [])
    },
    addProvider(provider) {
      this.whatsappProviders.push({
        provider: provider,
        providerConfiguration: {
          login: '',
          password: '',
          token: '',
          active: false
        }
      })
    },
    async saveEdit() {
      try {
        this.saving = true
        const providers = this.whatsappProviders.reduce((acc, { provider, providerConfiguration }) => {
          acc[provider] = providerConfiguration
          return acc
        }, {})
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_WHATSAPP_CREDENTIALS,
          variables: {
            id: this.accountId,
            data: providers
          }
        })
        this.editable = false
        this.$snackbar({ message: 'Credenciais whatsapp atualizadas com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao atualizar credentials de whatsapp', snackbarColor: '#F44336' })
      }
      this.saving = false
    },
    cancelRestoreAccount() {
      this.$emit('refresh')
      this.editable = false
    }
  }
}
</script>
